<template>
  <section>
    <!--工具条-->
    <el-form :inline="true" class="toolbar">
      <el-form-item>
        <el-button @click="addRoot" size="medium" type="success">添加根节点</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-tree
      :data="treeData"
      :props="defaultProps"
      node-key="id"
      v-loading="treeLoading"
      :expand-on-click-node="false"
      :render-content="renderContent"
      default-expand-all
      ref="tree"
    ></el-tree>
    <!--添加-->
    <el-dialog title="添加权限" :visible.sync="addDialogVisible" :close-on-click-modal="false">
      <el-form
        :model="addForm"
        label-position="left"
        label-width="auto"
        :rules="inputRule"
        ref="addForm"
      >
        <el-form-item label="Code" prop="code">
          <el-input v-model="addForm.code" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="addForm.name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="路径" prop="url">
          <el-input v-model="addForm.url" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="国际化key" prop="globeKey">
          <el-input v-model="addForm.globeKey" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="接口" prop>
          <el-input v-model="addForm.apiUrl" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addFormSubmit" :loading="addFormLoading">提 交</el-button>
      </div>
    </el-dialog>
    <!--修改-->
    <el-dialog title="修改权限" :visible.sync="editDialogVisible" :close-on-click-modal="false">
      <el-form
        :model="editForm"
        label-position="left"
        label-width="auto"
        :rules="inputRule"
        ref="editForm"
      >
        <el-form-item label="Code" prop="code">
          <el-input v-model="editForm.code" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="editForm.name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="路径" prop="url">
          <el-input v-model="editForm.url" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="国际化key" prop="globeKey">
          <el-input v-model="editForm.globeKey" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="接口" prop>
          <el-input v-model="editForm.apiUrl" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editFormSubmit" :loading="editFormLoading">提 交</el-button>
      </div>
    </el-dialog>
  </section>
</template>
<script>
import api from "../../api/userRole/Permission";
export default {
  data() {
    return {
      //Grid
      treeLoading: false,
      treeData: [],
      defaultProps: {
        children: "children",
        label: "permissionName"
      },
      //Form
      addForm: {
        code: "",
        name: "",
        url: "/",
        globeKey: "",
        apiUrl: "",
        parentId: "0"
      },
      editForm: {
        id: "",
        code: "",
        name: "",
        globeKey: "",
        apiUrl: "",
        url: "/"
      },
      inputRule: {
        code: [
          {
            required: true,
            message: "请输入Code",
            trigger: "blur"
          }
        ],
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur"
          }
        ],
        url: [
          {
            required: true,
            message: "请输入路径",
            trigger: "blur"
          }
        ],
        globeKey: [
          {
            required: true,
            message: "请输入国际化Key",
            trigger: "blur"
          }
        ]
      },
      //Tree
      addDialogVisible: false,
      editDialogVisible: false,
      addFormLoading: false,
      editFormLoading: false
    };
  },
  methods: {
    addRoot() {
      this.addDialogVisible = true;
      this.$refs.addForm && this.$refs.addForm.resetFields();
      this.addForm.parentId = "0";
    },
    addNode(parent) {
      this.addDialogVisible = true;
      this.$refs.addForm && this.$refs.addForm.resetFields();
      this.addForm.parentId = parent.id;
    },
    edit(row) {
      this.editDialogVisible = true;
      this.editForm.id = row.id;
      this.editForm.code = row.code;
      this.editForm.name = row.name;
      this.editForm.url = row.url;
      this.editForm.globeKey = row.globeKey;
      this.editForm.apiUrl = row.apiUrl;
    },
    addFormSubmit() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          let content = Object.assign({}, this.addForm);
          let _data = {};
          _data.content = content;
          this.addFormLoading = true;
          api.addPermission(_data).then(res => {
            this.addFormLoading = false;
            if ("0000" == res.code) {
              this.addDialogVisible = false;
              this.bindTree();
              this.$message({
                message: "添加成功",
                type: "success"
              });
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    editFormSubmit() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          let content = Object.assign({}, this.editForm);
          let _data = {};
          _data.content = content;
          this.editFormLoading = true;
          api.editPermission(_data).then(res => {
            this.editFormLoading = false;
            if ("0000" == res.code) {
              this.editDialogVisible = false;
              this.bindTree();
              this.$message({
                message: "修改成功",
                type: "success"
              });
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    renderContent(h, { node, data, store }) {
      return (
        <span style="width:100%;">
          <span>
            <span style="font-size:14px;">
              {" "}
              {data.name}({data.url}){" "}
            </span>
          </span>
          <span style="float: right; margin-right: 20px">
            <el-button
              disabled={node.level != 1}
              type="text"
              size="mini"
              on-click={() => this.addNode(data)}
            >
              添加子权限
            </el-button>
            <el-button size="mini" type="text" on-click={() => this.edit(data)}>
              修改
            </el-button>
          </span>
        </span>
      );
    },
    bindTree() {
      this.treeLoading = true;
      let _data = {};
      api.permissionList(_data).then(res => {
        this.treeLoading = false;
        if ("0000" == res.code) {
          this.treeData = res.data.rows;
        } else {
          this.$message.error(res.message);
        }
      });
    }
  },
  created: function() {
    this.bindTree();
  }
};
</script>
<style>
.el-tree-node__expand-icon.expanded{
  margin-bottom: 10px;
}
.el-tree-node__content>.el-tree-node__expand-icon{
  margin-bottom: 10px;
}
</style>