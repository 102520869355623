import request from "@/utils/http/requset";

// 添加权限
function addPermission(data) {
  return request({
    url: "/manage/permission/create",
    method: "post",
    data: data,
    dataType: "jsonp"
  });
}

// 修改权限
function editPermission(data) {
  return request({
    url: "/manage/permission/update",
    method: "post",
    data: data,
    dataType: "jsonp"
  });
}

// 权限列表
function permissionList(data) {
  return request({
    url: "/manage/permission/search",
    method: "post",
    data: data,
    dataType: "jsonp"
  });
}
export default {
  addPermission,
  editPermission,
  permissionList
};
